import React from 'react'
import Layout from "../components/resuable/layout/layout";
import Hero from '../components/resuable/hero/hero';
import ContactDetailsSection from '../components/page/contact-components/contact-details-section/contact-details-section';
import ContactFormSection from '../components/page/contact-components/contact-form-section/contact-form-section';
import Joinus from '../components/resuable/joinus/joinus';

const Contact = () => {
  return (
    <Layout>
      <Hero
        title="Contact Us"
      >
        <p>Have a question?  We would love to answer any questions you have and share the adventure of LeadMirror.</p>
      </Hero>
      <ContactDetailsSection />
      <ContactFormSection />
      <Joinus />
    </Layout>
  )
}

export default Contact
