import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Section from '../../../resuable/section/section'
import { colors } from '../../../../utils/styles/styles'
import FormMessage from '../../../resuable/form/form-message/form-message'
import FormField from '../../../resuable/form/form-field/form-field'
import { isEmailValid, isPhoneValid, allFormFieldsFilled } from '../../../../utils/sanitization/form-validation'

import Loader from '../../../../assets/button/loader.inline.svg'

const StyledContactFormSection = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin-bottom: 40px;
    font-size: 36px;
  }
  form.container__form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div.container__form--left, div.container__form--right {
      flex: 30%;
      /* border: 1px solid red; */
      .contact-form {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
        label {
          /* width: 100%; */
          margin-bottom: 4px;
          font-size: 12px;
          color: #fff;
        }
        input, textarea {
          margin-top: 4px;
          padding: 2px 10px;
          font-size: 16px;
          color: #fff;
          background: #161616;
          border: none;

          &:focus {
            box-shadow: 0px 0px 10px rgba(73, 66, 216, 0.65);
            /* outline: none; */
            outline: 1px solid ${colors.sec};
          }
          &::placeholder {
            color: ${colors.lightGray};
          }
        }
        input {
          width: 100%;
          height: 42px;
          border: none;
        }
        textarea {
          width: 100%;
          height: 100%;
        }
      }
      .message {
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
    }
    div.container__form--left {
      margin-right: 20px;
    }
    div.container__form--right {
      margin-left: 20px;
    }
    div.container__form--button {
      width: 100%;
      padding-top: 30px;
      display: flex;
      justify-content: center;
      button {
        cursor: pointer;
        padding: 10px 36px;
        display: flex;
        justify-content: center;
        border: none;
        border-radius: 30px;
        color: #fff;
        background: ${colors.priGradient};
        font-size: 16px;
        svg {
          height: 24px;
        }
      }
    }
  }
  div.container__status {
    width: 100%;
    padding: 20px 14px;
    background: green;
    color: #fff;
  }
  @media (max-width: 768px) {
    form.container__form {
      width: 100%;
      > * {
        flex: 100%;
      }
      div.container__form--left {
        margin-right: 0px;
      }
      div.container__form--right {
        margin-left: 0px;
      }
    }
  }
`

const ContactFormSection = () => {
  const formRef = useRef(null)
  const [responseStatus, setResponseStatus] = useState('hidden'); //submitting submitted
  const [input, setInput] = useState({
    name: '',
    organization: '',
    contact_num: '',
    email_id: '',
    message: ''
  });

  // CDM
  useEffect(() => {
    console.log(formRef.current);
  }, []);

  // Form Input Change
  const handleChange = (e) => {
    let newInput = input
    newInput[e.target.name] = e.target.value
    setInput({
      ...newInput
    })
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();


    // API Config
    const config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input)
    }

    //Checking to see if all fields were filled.
    if (allFormFieldsFilled(input)) {
      // Transition State
      setResponseStatus('submitting');
      try {
        const res = await fetch('https://api.leadmirror.com/api/v1/homepage/contact-us/', config);
        const status = res.status;
        setResponseStatus('submitted')
        console.log(status)
      } catch (err) {
        setResponseStatus('failed')
      }
    }
    // Handle Incomplete Form
    else {
      alert('Please fill all fields')
    }
  };

  return (
    <Section>
      <StyledContactFormSection>
        <h5>Leave a message</h5>
        <form className="container__form" onSubmit={handleSubmit} ref={formRef}>
          <div className="container__form--left">

            {/* Name */}
            <FormField className="contact-form">
              <label htmlFor="name">Name *</label>
              <FormMessage isVisible={input.name === ''}>
                This field is Required
              </FormMessage>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={input.name}
                onChange={handleChange}
                isrequired
              />
            </FormField>

            {/* Org */}
            <FormField className="contact-form">
              <label htmlFor="organization">Organization *</label>
              <FormMessage isVisible={input.organization === ''}>
                This field is Required
              </FormMessage>
              <input
                type="text"
                name="organization"
                placeholder="Organization Name"
                value={input.organization}
                onChange={handleChange}
                isrequired
              />
            </FormField>

            {/* Phone */}
            <FormField className="contact-form">
              <label htmlFor="contact_num">Contact Number *</label>
              <FormMessage isVisible={input.contact_num === ''}>
                This field is Required
              </FormMessage>
              <FormMessage isVisible={!isPhoneValid(input.contact_num)}>
                Please Enter a Valid Phone
              </FormMessage>
              <input
                type="text"
                name="contact_num"
                placeholder="9123456789"
                value={input.contact_num}
                onChange={handleChange}
                isrequired
              />
            </FormField>

            {/* Email */}
            <FormField className="contact-form">
              <label htmlFor="email_id">Email *</label>
              <FormMessage isVisible={input.email_id === ''}>
                This field is Required
              </FormMessage>
              <FormMessage isVisible={!isEmailValid(input.email_id)}>
                Please Enter a Valid Email
              </FormMessage>
              <input
                type="text"
                name="email_id"
                placeholder="email@email.com"
                value={input.email_id}
                onChange={handleChange}
                isrequired
              />
            </FormField>
          </div>

          <div className="container__form--right">
            {/* Message */}
            <FormField className="contact-form message">
              <label htmlFor="message">Message *</label>
              <FormMessage isVisible={input.message === ''}>
                This field is Required
              </FormMessage>
              <textarea
                name="message"
                placeholder="Hello There!"
                value={input.message}
                onChange={handleChange}
                isrequired
              />
            </FormField>
          </div>
          <div className="container__form--button">
            <button id="contact__form-submit">
              {
                responseStatus === 'submitting' ? (
                  <Loader />
                ) : 'Submit'
              }
            </button>
          </div>
        </form>
        {
          responseStatus === 'submitted' ? (
            <div className="container__status">
              You message has been sent!
            </div>
          ) : null
        }
      </StyledContactFormSection>
    </Section>
  )
}

ContactFormSection.defaultProps = {

}

export default ContactFormSection