import React from 'react'
import styled from 'styled-components'

const StyledFormMessage = styled.div`
  /* width: 80%; */
  /* margin-bottom: 4px; */
  padding: 4px 12px;
  color: #ccc;
  background: rgba(198,40,40 ,0.5);
  border-radius: 30px;
  line-height: 100%;
  font-size: 10px;
  font-weight: bold;
`

const FormMessage = ({
  children,
  isVisible
}) => {
  return (
    isVisible ? (
      <StyledFormMessage>
        {children}
      </StyledFormMessage>
    ) : null
  )
}

FormMessage.defaultProps = {
  isVisible: false,
}

export default FormMessage