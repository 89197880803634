import styled from 'styled-components'
import { dimensions } from '../../../../utils/styles/styles'

const FormField = styled.div`
  width: 100%;
  max-width: ${props => props.fullwidth ? '100%' : dimensions.siteWidth};
  display: flex;
  flex-direction: column;
`

FormField.defaultProps = {
  fullwidth: false,
}
export default FormField