import React from 'react'
import styled from 'styled-components'
import Section from '../../../resuable/section/section'
import Row from '../../../resuable/row/row'
import ContactDetailsColumn from './contact-details-column/contact-details-column'

import Mail from '../../../../assets/contact/Mail.inline.svg'
import Phone from '../../../../assets/contact/Phone.inline.svg'
import Time from '../../../../assets/contact/Time.inline.svg'

import { colors } from '../../../../utils/styles/styles'

// For styles of ContactDetailsColumn see the file
const StyledContactDetailsSection = styled.div`
  width: 100%;
  div.group__detail--heading {
    margin-bottom: 20px;
    .heading {
      display: inline;
      margin-left: 16px;
      font-size: 2.8rem;
    }
  }
  div.group__detail--point {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    span {
      display: inline-block;
    }
    span.value, span.value a {
      color: ${colors.pri};
      text-decoration: none;
    }
  }
`

const ContactDetailsSection = (props) => {
  return (
    <Section>
      <StyledContactDetailsSection>
        <Row columns={3}>
          {/* Column 1 : Email */}
          <ContactDetailsColumn>
            <div className="group__detail--heading">
              <Mail />
              <h3 className="heading">Email Support</h3>
            </div>
            <div className="group__detail--point">
              <span className="label">Business enquiries</span>
              <span className="value"><a href="mailto:sales@leadmirror.com">sales@leadmirror.com</a></span>
            </div>
            <div className="group__detail--point">
              <span className="label">General enquiries</span>
              <span className="value"><a href="mailto:info@leadmirror.com">info@leadmirror.com</a></span>
            </div>
            <div className="group__detail--point">
              <span className="label">Press, Media, Outreach</span>
              <span className="value"><a href="mailto:media@leadmirror.com">media@leadmirror.com</a></span>
            </div>
          </ContactDetailsColumn>
          {/* Column 2 : Phone Nos */}
          <ContactDetailsColumn>
            <div className="group__detail--heading">
              <Phone />
              <h3 className="heading">Phone Support</h3>
            </div>
            <div className="group__detail--point">
              <span className="label">Waterloo :</span>
              <span className="value"><a href="tel:+1 (226) 444 0034">+1 (226) 444 0034</a></span>
            </div>
            <div className="group__detail--point">
              <span className="label">Stockholm :</span>
              <span className="value"><a href="tel:+46 (844) 686 935">+46 (844) 686 935</a></span>
            </div>
            <div className="group__detail--point">
              <span className="label">Melbourne :</span>
              <span className="value"><a href="tel:+61 (390) 711 836">+61 (390) 711 836</a></span>
            </div>
            <div className="group__detail--point">
              <span className="label">Hyderabad :</span>
              <span className="value"><a href="tel:+91 4851 0900">+91 (4851) 0900</a></span>
            </div>
          </ContactDetailsColumn>
          {/* Column 3: Timing */}
          <ContactDetailsColumn>
            <div className="group__detail--heading">
              <Time />
              <h3 className="heading">Working Hours</h3>
            </div>
            <div className="group__detail--point">
              <span className="label">Days :</span>
              <span className="value">Monday - Sunday</span>
            </div>
            <div className="group__detail--point">
              <span className="label">Sales :</span>
              <span className="value">09:30 AM - 01:30 AM (IST)</span>
            </div>
            <div className="group__detail--point">
              <span className="label">Tech Support :</span>
              <span className="value">09:00 AM - 07:00 PM (IST)</span>
            </div>
          </ContactDetailsColumn>
        </Row>
      </StyledContactDetailsSection>
    </Section>
  )
}

ContactDetailsSection.defaultProps = {

}

export default ContactDetailsSection