import React from 'react'
import styled from 'styled-components'
import Column from '../../../../resuable/column/column'

const StyledContactDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const ContactDetailsColumn = (props) => {
  return (
    <Column
      isCentered={true}
    >
      <StyledContactDetailsColumn>
        {props.children}
      </StyledContactDetailsColumn>
    </Column>
  )
}

ContactDetailsColumn.defaultProps = {

}

export default ContactDetailsColumn